// AwardsCarousel.jsx
import React, { useEffect, useState, useRef } from 'react';
import '../styles/AwardsCarousel.css';
import Slider from 'react-slick';
import { db } from '../firebaseConfig';
import { collection, getDocs } from 'firebase/firestore';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const AwardsCarousel = () => {
  const [awards, setAwards] = useState([]);
  const [mainIndex, setMainIndex] = useState(1);
  const sliderRef = useRef(null);

  useEffect(() => {
    const fetchAwards = async () => {
      const awardsCollection = collection(db, 'premios');
      const awardsSnapshot = await getDocs(awardsCollection);
      const awardsList = awardsSnapshot.docs.map((doc) => doc.data());
      setAwards(awardsList);
      if (sliderRef.current) {
        sliderRef.current.slickGoTo(1); // Set the initial slide to the middle one
      }
    };
    fetchAwards();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '0',
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerPadding: '0',
        },
      },
    ],
    beforeChange: (current, next) => {
      setMainIndex(next);
    },
  };

  return (
    <section className="awards-section">
      <h2>Nuestros Premios</h2>
      <Slider ref={sliderRef} {...settings} className="awards-carousel">
        {awards?.map((award, index) => (
          <div
            key={index}
            className={`award-card ${index === mainIndex ? 'active' : 'small'}`}
          >
            <img
              src={award.foto}
              alt={`Premio ${index + 1}`}
              className={index === mainIndex ? 'main-award-image' : 'small-award-image'}
            />
            {/* {index === mainIndex && (
              <div className="main-award-info">
                <p className="award-name">{award.nombre}</p>
                <p className="award-prize">${award.premio}</p>
              </div>
            )} */}
          </div>
        ))}
      </Slider>
    </section>
  );
};

export default AwardsCarousel;
