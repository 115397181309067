// src/components/LandingPage.js
import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/LandingPage.css';
import AwardsCarousel from './AwardsCarousel';


function LandingPage() {
  return (
    <div>
      {/* Encabezado */}
      {/* <header className="header">
        <div className="logo">KKPoker</div>
        <nav className="navbar">
          <Link to="/" className="nav-link">Inicio</Link>
          <Link to="/about" className="nav-link">Sobre Nosotros</Link>
          <Link to="/products" className="nav-link">Productos</Link>
          <Link to="/contact" className="nav-link">Contacto</Link>
          <button className="nav-button">FAQ</button>
        </nav>
      </header> */}

      {/* Sección de Bienvenida */}
      <section className="hero-section">
        <div className="hero-content">
          <h1>Bienvenidos a La Pecera</h1>
          <p>En La Pecera, encontrarás todo lo que necesitas para disfrutar de una experiencia de juego emocionante y llena de emoción.</p>
          <a
            href="https://kkpoker.club/peceraweb"
            target="_blank"
            rel="noopener noreferrer"
          >
          <button className="cta-button">Únete ahora</button>
          </a>
        </div>
      </section>

      {/* Sección de Beneficios */}
      <section className="benefits-section">
        <h2>Los Beneficios del Club</h2>
        <p>En La Pecera, ofrecemos una variedad de beneficios para nuestros miembros.</p>
        <div className="benefits-content">
          <div className="benefit-item">
            <img src="/images/free.jpg" alt="Beneficio 1" />
            <h3>FreeRolls</h3>
            <p>Torneos gratuitos todos los días y torneos semanales exclusivos para los miembros del club. Perfecto para quienes desean construir su banca sin necesidad de invertir dinero.</p>
          </div>
          <div className="benefit-item">
            <img src="/images/clases.jpg" alt="Beneficio 1" />
            <h3>Clases Grupales</h3>
            <p>Realizamos cuatro clases mensuales a través de Discord, las cuales son anunciadas previamente en nuestros canales oficiales del club.</p>
          </div>
          <div className="benefit-item">
            <img src="/images/objetivos.jpg" alt="Beneficio 1" />
            <h3>Coaching Personalizado</h3>
            <p>Revisamos tus manos, establecemos objetivos personalizados y te ayudamos a mejorar tu estrategia de juego.</p>
          </div>
          <div className="benefit-item">
            <img src="/images/comunidad.jpg" alt="Beneficio 1" />
            <h3>Nuestra Comunidad</h3>
            <p>Conoce a nuestra comunidad de jugadores y mejora tu experiencia de juego con nuestros recursos exclusivos.</p>
          </div>
          <div className="benefit-item">
            <img src="/images/streamer.jpg" alt="Beneficio 2" />
            <h3>Aprende de los Mejores</h3>
            <p>Descubre a los mejores streamers y jugadores profesionales del club, y aprende de sus estrategias y tácticas para mejorar tu juego.</p>
          </div>
        </div>
      </section>

      <div className='awardsSize'>
        {/* Sección de Premios */}
        <AwardsCarousel />
      </div>

{/* Sección de Streamers */}
<section className="streamers-section">
  <h2>Nuestros Streamers</h2>

  <div className="streamer-card">
    <div className="streamer-image-container">
      <img src="/images/tibu.jpg" alt="Streamer 1" className="streamer-image" />
      <img src="/images/marco.png" alt="Marco" className="streamer-frame" />
    </div>
    <p className="streamer-name">Tibu</p>
  </div>

  <div className="streamer-card">
    <div className="streamer-image-container">
      <img src="/images/pato.jpg" alt="Streamer 2" className="streamer-image" />
      <img src="/images/marco.png" alt="Marco" className="streamer-frame" />
    </div>
    <p className="streamer-name">Pato</p>
  </div>

  <div className="streamer-card">
    <div className="streamer-image-container">
      <img src="/images/negro.jpg" alt="Streamer 3" className="streamer-image" />
      <img src="/images/marco.png" alt="Marco" className="streamer-frame" />
    </div>
    <p className="streamer-name">Negrazo</p>
  </div>

  <div className="streamer-card">
    <div className="streamer-image-container">
      <img src="/images/tala.jpg" alt="Streamer 4" className="streamer-image" />
      <img src="/images/marco.png" alt="Marco" className="streamer-frame" />
    </div>
    <p className="streamer-name">Tala</p>
  </div>

  <div className="twitch-video">
    <iframe
      src="https://player.twitch.tv/?channel=lapeceraclub&parent=www.lapeceraclub.com"
      title="Twitch Stream"
      allowFullScreen
    ></iframe>
  </div>
</section>

      {/* Sección de Redes Sociales */}
      <section className="social-media-section">
        <h2>Nuestras Redes</h2>
        <p>Síguenos en nuestras redes sociales para estar al tanto de las últimas novedades.</p>
        <div className="social-media-icons">
          <a
            href="https://www.instagram.com/lapecera.club/?hl=es"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="https://img.icons8.com/?size=100&id=Xy10Jcu1L2Su&format=png&color=000000" alt="Instagram" />
          </a>
          <a
            href="https://www.youtube.com/@lapeceraclub"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="https://img.icons8.com/?size=100&id=9a46bTk3awwI&format=png&color=000000" alt="YouTube" />
          </a>
          <a
            href="https://www.twitch.tv/lapeceraclub"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="https://img.icons8.com/?size=100&id=7qFfaszJSlTs&format=png&color=000000" alt="Twitch" />
          </a>
        </div>
      </section>

      {/* Pie de Página */}
      <footer className="footer">
        <p>© 2024 La Pecera Club. Todos los derechos reservados.</p>
      </footer>
    </div>
  );
}
export default LandingPage;
