// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { UserProvider } from './contexts/UserContext';
import { AdminProvider } from './contexts/AdminContext';
import Navbar from './components/Navbar';
import LandingPage from './components/LandingPage';
import Login from './components/Login';
import Clases from './components/Clases';
import ProtectedRoute from './components/ProtectedRoute';
import RegistradorDeTorneos from './components/RegistradorDeTorneos';

import AdminLogin from './components/AdminLogin';
import AdminPanel from './components/AdminPanel';
import ProtectedAdminRoute from './components/ProtectedAdminRoute';

function App() {
  return (
    <UserProvider>
      <AdminProvider>
        <Router>
          <Navbar />
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/login" element={<Login />} />
            <Route
              path="/torneos"
              element={
                <ProtectedRoute>
                  <RegistradorDeTorneos />
                </ProtectedRoute>
              }
            />
            <Route
              path="/clases"
              element={
                <ProtectedRoute>
                  <Clases />
                </ProtectedRoute>
              }
            />
            {/* Rutas para administradores */}
            <Route path="/admin" element={<AdminLogin />} />
            <Route
              path="/admin/*"
              element={
                <ProtectedAdminRoute>
                  <AdminPanel />
                </ProtectedAdminRoute>
              }
            />
          </Routes>
        </Router>
      </AdminProvider>
    </UserProvider>
  );
}

export default App;