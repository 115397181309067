// src/components/AdminInferior.js
import React, { useState, useEffect, useContext } from 'react';
import { db } from '../firebaseConfig';
import {
  collection,
  getDocs,
  query,
  where,
  addDoc,
} from 'firebase/firestore';
import { AdminContext } from '../contexts/AdminContext';
import {
  Container,
  Typography,
  TextField,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  bronze: {
    color: '#cd7f32',
    fontWeight: 'bold',
  },
  silver: {
    color: '#C0C0C0',
    fontWeight: 'bold',
  },
  gold: {
    color: '#FFD700',
    fontWeight: 'bold',
  },
  gananciaPositiva: {
    color: 'green',
    fontWeight: 'bold',
  },
  gananciaNegativa: {
    color: 'red',
    fontWeight: 'bold',
  },
});

function AdminInferior() {
  const classes = useStyles();
  const [usuarios, setUsuarios] = useState([]);
  const [newUser, setNewUser] = useState({ usuario: '', clave: '' });
  const { admin } = useContext(AdminContext);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    fetchUsuarios();
    // eslint-disable-next-line
  }, [admin.email]);

  // Función para obtener los usuarios y sus ganancias mensuales
  const fetchUsuarios = async () => {
    // Obtener usuarios donde el agente es el administrador actual
    const q = query(
      collection(db, 'usuarios'),
      where('agente', '==', admin.email)
    );
    const usuariosSnapshot = await getDocs(q);
    const usuariosData = usuariosSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    // Obtener el inicio y fin del mes actual
    const startOfMonth = new Date();
    startOfMonth.setDate(1);
    startOfMonth.setHours(0, 0, 0, 0);

    const endOfMonth = new Date(startOfMonth);
    endOfMonth.setMonth(endOfMonth.getMonth() + 1);

    // Función para formatear fecha a 'YYYY-MM-DD'
    function formatDateToString(date) {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Los meses son 0-indexados
      const day = date.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    }

    const startOfMonthString = formatDateToString(startOfMonth);
    const endOfMonthString = formatDateToString(endOfMonth);

    // Para cada usuario, calcular las ganancias totales mensuales
    const usuariosConGanancias = await Promise.all(
      usuariosData.map(async (usuario) => {
        const torneosRef = collection(db, 'usuarios', usuario.id, 'torneos');
        const torneosQuery = query(
          torneosRef,
          where('fecha', '>=', startOfMonthString),
          where('fecha', '<', endOfMonthString)
        );
        const torneosSnapshot = await getDocs(torneosQuery);

        let totalGanancias = 0;
        torneosSnapshot.forEach((torneoDoc) => {
          const torneoData = torneoDoc.data();
          // Calcular gananciaTorneo
          const buyIn = torneoData.buyIn || 0;
          const compras = torneoData.compras || 0;
          const premio = torneoData.premio || 0;
          const gananciaTorneo = premio - buyIn * compras;
          totalGanancias += gananciaTorneo;
        });

        return { ...usuario, totalGanancias };
      })
    );

    setUsuarios(usuariosConGanancias);
  };

  // Función para crear un nuevo usuario con confirmación
  const handleCreateUser = async () => {
    const confirmacion = window.confirm(
      `¿Estás seguro de crear "${newUser.usuario}" con la clave "${newUser.clave}"?`
    );
    if (!confirmacion) return;

    const usuarioData = {
      usuario: newUser.usuario,
      clave: newUser.clave,
      rango: 1,
      agente: admin.email,
    };

    await addDoc(collection(db, 'usuarios'), usuarioData);
    setNewUser({ usuario: '', clave: '' });

    // Actualizar la lista de usuarios
    fetchUsuarios();
  };

  // Función para abrir el diálogo de confirmación
  const handleOpenDialog = (e) => {
    e.preventDefault();
    setOpenDialog(true);
  };

  // Función para cerrar el diálogo de confirmación
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <Container maxWidth="md" style={{ marginTop: '2rem' }}>
      {/* Crear Nuevo Usuario */}
      <Typography variant="h4" align="center" gutterBottom>
        Crear Nuevo Usuario
      </Typography>
      <form
        onSubmit={handleOpenDialog}
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <TextField
          label="Usuario"
          value={newUser.usuario}
          onChange={(e) =>
            setNewUser({ ...newUser, usuario: e.target.value })
          }
          required
          style={{ marginBottom: '1rem', width: '300px' }}
          InputLabelProps={{
            style: { color: 'white' },
          }}
          InputProps={{
            style: { color: 'white' },
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'white',
              },
              '&:hover fieldset': {
                borderColor: 'white',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'white',
              },
            },
            '& .MuiInputLabel-root': {
              color: 'white',
            },
          }}
        />
        <TextField
          label="Contraseña"
          type="password"
          value={newUser.clave}
          onChange={(e) =>
            setNewUser({ ...newUser, clave: e.target.value })
          }
          required
          style={{ marginBottom: '1rem', width: '300px' }}
          InputLabelProps={{
            style: { color: 'white' },
          }}
          InputProps={{
            style: { color: 'white' },
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'white',
              },
              '&:hover fieldset': {
                borderColor: 'white',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'white',
              },
            },
            '& .MuiInputLabel-root': {
              color: 'white',
            },
          }}
        />
        <Button variant="contained" color="primary" type="submit">
          Crear Usuario
        </Button>
      </form>

      {/* Diálogo de confirmación */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Confirmar Creación de Usuario</DialogTitle>
        <DialogContent>
          <Typography>
            ¿Estás seguro de crear "{newUser.usuario}" con la clave "{newUser.clave}"?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            Cancelar
          </Button>
          <Button
            onClick={() => {
              handleCreateUser();
              handleCloseDialog();
            }}
            color="primary"
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Tus Usuarios */}
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        style={{ marginTop: '2rem' }}
      >
        Tus Usuarios
      </Typography>

      {/* Mostrar la lista de usuarios */}
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Usuario</TableCell>
              <TableCell>Rango</TableCell>
              <TableCell>Ganancias Totales Mensuales</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {usuarios.map((usuario) => (
              <TableRow key={usuario.id}>
                <TableCell>{usuario.usuario}</TableCell>
                <TableCell>
                  {usuario.rango === 1 && (
                    <span className={classes.bronze}>Bronce</span>
                  )}
                  {usuario.rango === 2 && (
                    <span className={classes.silver}>Plata</span>
                  )}
                  {usuario.rango === 3 && (
                    <span className={classes.gold}>Oro</span>
                  )}
                </TableCell>
                <TableCell
                  className={
                    usuario.totalGanancias >= 0
                      ? classes.gananciaPositiva
                      : classes.gananciaNegativa
                  }
                >
                  {usuario.totalGanancias
                    ? usuario.totalGanancias.toLocaleString('es-ES')
                    : 0}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>

      {/* Mostrar el total de usuarios */}
      <Typography
        variant="h6"
        align="right"
        style={{ marginTop: '1rem', fontWeight: 'bold' }}
      >
        Total de usuarios: {usuarios.length}
      </Typography>
    </Container>
  );
}

export default AdminInferior;
