import React, { useState, useEffect, useContext } from "react";
import { db } from "../firebaseConfig";
import {
  collection,
  addDoc,
  query,
  onSnapshot,
  updateDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { UserContext } from "../contexts/UserContext";
import { format, parseISO, isSameDay, isSameMonth, isSameYear } from "date-fns";

// Importamos componentes de Material-UI
import {
  TextField,
  Button,
  Typography,
  Grid,
  Paper,
  IconButton,
  Card,
  Fab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import HistoryIcon from "@mui/icons-material/History";

// Importamos los DatePickers de MUI
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

// Importamos react-countup para la animación de números
import CountUp from "react-countup";

function RegistradorDeTorneos() {
  const { user } = useContext(UserContext);
  const [torneos, setTorneos] = useState([]);
  const [newTorneo, setNewTorneo] = useState({
    nombre: "",
    buyIn: "",
    compras: 1,
    premio: "0", // Premio inicializado en 0
  });
  const [gananciaDiaria, setGananciaDiaria] = useState(0);
  const [gananciaMensual, setGananciaMensual] = useState(0);

  // Nuevos estados para el total de torneos jugados
  const [totalTorneosDia, setTotalTorneosDia] = useState(0);
  const [totalTorneosMes, setTotalTorneosMes] = useState(0);

  // Estados para animación
  const [startAnimation, setStartAnimation] = useState(false);

  // Estados para el historial
  const [openHistorial, setOpenHistorial] = useState(false);
  const [filtro, setFiltro] = useState("dia"); // 'dia', 'mes', 'todos'
  const [torneosFiltrados, setTorneosFiltrados] = useState([]);
  const [gananciaFiltrada, setGananciaFiltrada] = useState(0);
  const [totalTorneosFiltrados, setTotalTorneosFiltrados] = useState(0);

  // Estados para los pickers
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedMonth, setSelectedMonth] = useState(new Date());

  // Función auxiliar para parsear números de forma segura
  const parseNumber = (value) => {
    const parsed = parseFloat(value);
    return isNaN(parsed) ? 0 : parsed;
  };

  useEffect(() => {
    if (!user) return;

    const torneosRef = collection(db, "usuarios", user.id, "torneos");
    const q = query(torneosRef);

    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        const torneosData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setTorneos(torneosData);
      },
      (error) => {
        console.error("Error al obtener los torneos:", error);
        setTorneos([]); // Asegurarse de que 'torneos' sea un array vacío en caso de error
      }
    );

    return () => unsubscribe();
  }, [user]);

  useEffect(() => {
    calcularGanancias();
  }, [torneos]);

  const calcularGanancias = () => {
    const today = format(new Date(), "yyyy-MM-dd");
    const startMonth = format(new Date(), "yyyy-MM-01");

    let gananciaDia = 0;
    let gananciaMes = 0;

    // Variables para contar los torneos
    let contadorDia = 0;
    let contadorMes = 0;

    torneos.forEach((torneo) => {
      const torneoDate = torneo.fecha;

      // Parseamos los valores numéricos de forma segura
      const buyIn = parseNumber(torneo.buyIn);
      const compras = parseNumber(torneo.compras);
      const premio = parseNumber(torneo.premio);

      const gananciaTorneo = premio - buyIn * compras;

      if (torneoDate === today) {
        gananciaDia += gananciaTorneo;
        contadorDia += 1; // Incrementamos el contador diario
      }
      if (torneoDate >= startMonth) {
        gananciaMes += gananciaTorneo;
        contadorMes += 1; // Incrementamos el contador mensual
      }
    });

    setGananciaDiaria(gananciaDia);
    setGananciaMensual(gananciaMes);

    // Actualizamos los estados con los contadores
    setTotalTorneosDia(contadorDia);
    setTotalTorneosMes(contadorMes);

    // Iniciamos la animación después de calcular las ganancias
    setStartAnimation(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewTorneo((prev) => ({ ...prev, [name]: value }));
  };

  const agregarTorneo = async () => {
    // No es necesario validar los campos, ya que los valores vacíos se considerarán como 0

    const torneoData = {
      ...newTorneo,
      compras: parseNumber(newTorneo.compras),
      buyIn: parseNumber(newTorneo.buyIn),
      premio: parseNumber(newTorneo.premio),
      nombre: newTorneo.nombre || "Sin nombre",
      fecha: format(new Date(), "yyyy-MM-dd"),
    };

    try {
      const torneosRef = collection(db, "usuarios", user.id, "torneos");
      await addDoc(torneosRef, torneoData);
      setNewTorneo({ nombre: "", buyIn: "", compras: 1, premio: "0" }); // Restablecer el formulario
    } catch (error) {
      console.error("Error al agregar el torneo:", error);
    }
  };

  const eliminarTorneo = async (torneoId) => {
    try {
      const torneoDoc = doc(db, "usuarios", user.id, "torneos", torneoId);
      await deleteDoc(torneoDoc);
    } catch (error) {
      console.error("Error al eliminar el torneo:", error);
    }
  };

  const editarTorneo = async (torneoId, field, value) => {
    try {
      const torneoDoc = doc(db, "usuarios", user.id, "torneos", torneoId);
      if (["buyIn", "compras", "premio"].includes(field)) {
        await updateDoc(torneoDoc, { [field]: parseNumber(value) });
      } else {
        await updateDoc(torneoDoc, { [field]: value || "Sin nombre" });
      }
    } catch (error) {
      console.error("Error al editar el torneo:", error);
    }
  };

  const today = format(new Date(), "yyyy-MM-dd");

  // Filtrar los torneos del día actual
  const torneosDeHoy = torneos.filter((torneo) => torneo.fecha === today);

  // Función para obtener el estilo basado en el valor
  const getColorByValue = (value) => {
    if (value > 0) return "#00ff00"; // Verde
    if (value < 0) return "#ff0000"; // Rojo
    return "white"; // Blanco si es cero
  };

  // Funciones para el historial
  const handleOpenHistorial = () => {
    setOpenHistorial(true);
    filtrarTorneos(filtro);
  };

  const handleCloseHistorial = () => {
    setOpenHistorial(false);
  };

  const handleFiltroChange = (e) => {
    const nuevoFiltro = e.target.value;
    setFiltro(nuevoFiltro);
    filtrarTorneos(nuevoFiltro);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    filtrarTorneos(filtro, date, selectedMonth);
  };

  const handleMonthChange = (date) => {
    setSelectedMonth(date);
    filtrarTorneos(filtro, selectedDate, date);
  };

  const filtrarTorneos = (
    criterio,
    fechaDia = selectedDate,
    fechaMes = selectedMonth
  ) => {
    let torneosFiltrados = [];

    if (criterio === "dia") {
      torneosFiltrados = torneos.filter((torneo) =>
        isSameDay(parseISO(torneo.fecha), fechaDia)
      );
    } else if (criterio === "mes") {
      torneosFiltrados = torneos.filter(
        (torneo) =>
          isSameMonth(parseISO(torneo.fecha), fechaMes) &&
          isSameYear(parseISO(torneo.fecha), fechaMes)
      );
    } else {
      torneosFiltrados = torneos;
    }

    setTorneosFiltrados(torneosFiltrados);

    // Calcular ganancia y total de torneos filtrados
    let gananciaTotal = 0;
    torneosFiltrados.forEach((torneo) => {
      const buyIn = parseNumber(torneo.buyIn);
      const compras = parseNumber(torneo.compras);
      const premio = parseNumber(torneo.premio);
      const gananciaTorneo = premio - buyIn * compras;
      gananciaTotal += gananciaTorneo;
    });

    setGananciaFiltrada(gananciaTotal);
    setTotalTorneosFiltrados(torneosFiltrados.length);
  };

  return (
    <div style={{ padding: "16px", position: "relative" }}>
      <Typography variant="h4" gutterBottom style={{ color: "white" }}>
        Registrador de Torneos
      </Typography>

      <Grid container spacing={2}>
        {/* Tarjeta de Ganancia Diaria */}
        <Grid item xs={12} sm={6}>
          <Card
            style={{
              backgroundColor: "rgba(255, 255, 255, 0.1)",
              textAlign: "center",
              padding: "16px",
            }}
          >
            <Typography variant="h6" style={{ color: "white" }}>
              Ganancia Diaria
            </Typography>
            {startAnimation && (
              <Typography
                variant="h4"
                style={{
                  color: getColorByValue(gananciaDiaria),
                }}
              >
                $
                <CountUp
                  start={0}
                  end={gananciaDiaria}
                  duration={2}
                  decimals={2}
                  separator=","
                />
              </Typography>
            )}
          </Card>
        </Grid>

        {/* Tarjeta de Ganancia Mensual */}
        <Grid item xs={12} sm={6}>
          <Card
            style={{
              backgroundColor: "rgba(255, 255, 255, 0.1)",
              textAlign: "center",
              padding: "16px",
            }}
          >
            <Typography variant="h6" style={{ color: "white" }}>
              Ganancia Mensual
            </Typography>
            {startAnimation && (
              <Typography
                variant="h4"
                style={{
                  color: getColorByValue(gananciaMensual),
                }}
              >
                $
                <CountUp
                  start={0}
                  end={gananciaMensual}
                  duration={2}
                  decimals={2}
                  separator=","
                />
              </Typography>
            )}
          </Card>
        </Grid>
      </Grid>

      {/* Agregamos las tarjetas de Total de Torneos */}
      <Grid container spacing={2} style={{ marginTop: "24px" }}>
        {/* Total de Torneos Jugados Hoy */}
        <Grid item xs={12} sm={6}>
          <Card
            style={{
              backgroundColor: "rgba(255, 255, 255, 0.1)",
              textAlign: "center",
              padding: "16px",
            }}
          >
            <Typography variant="h6" style={{ color: "white" }}>
              Torneos Jugados Hoy
            </Typography>
            {startAnimation && (
              <Typography variant="h4" style={{ color: "white" }}>
                <CountUp
                  start={0}
                  end={totalTorneosDia}
                  duration={2}
                  decimals={0}
                />
              </Typography>
            )}
          </Card>
        </Grid>

        {/* Total de Torneos Jugados en el Mes */}
        <Grid item xs={12} sm={6}>
          <Card
            style={{
              backgroundColor: "rgba(255, 255, 255, 0.1)",
              textAlign: "center",
              padding: "16px",
            }}
          >
            <Typography variant="h6" style={{ color: "white" }}>
              Torneos Jugados Este Mes
            </Typography>
            {startAnimation && (
              <Typography variant="h4" style={{ color: "white" }}>
                <CountUp
                  start={0}
                  end={totalTorneosMes}
                  duration={2}
                  decimals={0}
                />
              </Typography>
            )}
          </Card>
        </Grid>
      </Grid>

      {/* Formulario para agregar un nuevo torneo */}
      <div style={{ marginTop: "24px" }}>
        <Typography variant="h5" gutterBottom style={{ color: "white" }}>
          Agregar Nuevo Torneo
        </Typography>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={2}>
            <TextField
              name="nombre"
              label="Nombre del Torneo"
              variant="outlined"
              value={newTorneo.nombre}
              onChange={handleInputChange}
              fullWidth
              InputProps={{
                style: { color: "white" },
              }}
              InputLabelProps={{
                style: { color: "white" },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "white",
                  },
                  "&:hover fieldset": {
                    borderColor: "white",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "white",
                  },
                },
                "& .MuiInputLabel-root": {
                  color: "white",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              type="number"
              name="buyIn"
              label="Buy-in"
              variant="outlined"
              value={newTorneo.buyIn}
              onChange={handleInputChange}
              fullWidth
              InputProps={{
                style: { color: "white" },
              }}
              InputLabelProps={{
                style: { color: "white" },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "white",
                  },
                  "&:hover fieldset": {
                    borderColor: "white",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "white",
                  },
                },
                "& .MuiInputLabel-root": {
                  color: "white",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              type="number"
              name="compras"
              label="Compras"
              variant="outlined"
              value={newTorneo.compras}
              onChange={handleInputChange}
              fullWidth
              InputProps={{
                inputProps: { min: 1 },
                style: { color: "white" },
              }}
              InputLabelProps={{
                style: { color: "white" },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "white",
                  },
                  "&:hover fieldset": {
                    borderColor: "white",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "white",
                  },
                },
                "& .MuiInputLabel-root": {
                  color: "white",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              type="number"
              name="premio"
              label="Premio ganado"
              variant="outlined"
              value={newTorneo.premio}
              onChange={handleInputChange}
              fullWidth
              InputProps={{
                style: { color: "white" },
              }}
              InputLabelProps={{
                style: { color: "white" },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "white",
                  },
                  "&:hover fieldset": {
                    borderColor: "white",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "white",
                  },
                },
                "& .MuiInputLabel-root": {
                  color: "white",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={agregarTorneo}
              fullWidth
            >
              Agregar Torneo
            </Button>
          </Grid>
        </Grid>
      </div>

      {/* Lista de torneos del día */}
      <div style={{ marginTop: "24px" }}>
        <Typography variant="h5" gutterBottom style={{ color: "white" }}>
          Torneos de Hoy
        </Typography>
        {torneosDeHoy.length === 0 ? (
          <Typography style={{ color: "white" }}>
            No hay torneos registrados para hoy.
          </Typography>
        ) : (
          <div>
            {torneosDeHoy.map((torneo) => {
              // Parseamos los valores numéricos
              const buyIn = parseNumber(torneo.buyIn);
              const compras = parseNumber(torneo.compras);
              const premio = parseNumber(torneo.premio);
              const gananciaTorneo = premio - buyIn * compras;

              return (
                <Paper
                  key={torneo.id}
                  style={{
                    padding: "16px",
                    marginBottom: "16px",
                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                  }}
                >
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={2}>
                      <TextField
                        label="Nombre"
                        variant="outlined"
                        value={torneo.nombre || "Sin nombre"}
                        onChange={(e) =>
                          editarTorneo(torneo.id, "nombre", e.target.value)
                        }
                        fullWidth
                        InputProps={{
                          style: { color: "white" },
                        }}
                        InputLabelProps={{
                          style: { color: "white" },
                        }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "white",
                            },
                            "&:hover fieldset": {
                              borderColor: "white",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "white",
                            },
                          },
                          "& .MuiInputLabel-root": {
                            color: "white",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <TextField
                        type="number"
                        label="Buy-in"
                        variant="outlined"
                        value={torneo.buyIn}
                        onChange={(e) =>
                          editarTorneo(torneo.id, "buyIn", e.target.value)
                        }
                        fullWidth
                        InputProps={{
                          style: { color: "white" },
                        }}
                        InputLabelProps={{
                          style: { color: "white" },
                        }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "white",
                            },
                            "&:hover fieldset": {
                              borderColor: "white",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "white",
                            },
                          },
                          "& .MuiInputLabel-root": {
                            color: "white",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={1}>
                      <TextField
                        type="number"
                        label="Compras"
                        variant="outlined"
                        value={torneo.compras}
                        onChange={(e) =>
                          editarTorneo(torneo.id, "compras", e.target.value)
                        }
                        fullWidth
                        InputProps={{
                          inputProps: { min: 1 },
                          style: { color: "white" },
                        }}
                        InputLabelProps={{
                          style: { color: "white" },
                        }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "white",
                            },
                            "&:hover fieldset": {
                              borderColor: "white",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "white",
                            },
                          },
                          "& .MuiInputLabel-root": {
                            color: "white",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={1}>
                      <TextField
                        type="number"
                        label="Premio"
                        variant="outlined"
                        value={torneo.premio}
                        onChange={(e) =>
                          editarTorneo(torneo.id, "premio", e.target.value)
                        }
                        fullWidth
                        InputProps={{
                          style: { color: "white" },
                        }}
                        InputLabelProps={{
                          style: { color: "white" },
                        }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "white",
                            },
                            "&:hover fieldset": {
                              borderColor: "white",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "white",
                            },
                          },
                          "& .MuiInputLabel-root": {
                            color: "white",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Typography
                        variant="body1"
                        style={{
                          color: getColorByValue(gananciaTorneo),
                        }}
                      >
                        Ganancia: ${gananciaTorneo.toFixed(2)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={1}>
                      <IconButton
                        color="secondary"
                        onClick={() => eliminarTorneo(torneo.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Paper>
              );
            })}
          </div>
        )}
      </div>

      {/* Botón flotante para abrir el historial */}
      <Fab
        color="primary"
        aria-label="historial"
        onClick={handleOpenHistorial}
        style={{ position: "fixed", bottom: 16, right: 16 }}
      >
        <HistoryIcon />
      </Fab>

      {/* Modal del historial */}
      <Dialog
        open={openHistorial}
        onClose={handleCloseHistorial}
        fullWidth
        maxWidth="xl" // Cambiamos a "xl" para mayor ancho
        PaperProps={{
          style: {
            backgroundColor: "#333", // Fondo oscuro
            color: "white", // Texto blanco
          },
        }}
      >
        <DialogTitle>Historial de Torneos</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={4}>
              <Typography variant="body1">Filtrar por:</Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Select
                value={filtro}
                onChange={handleFiltroChange}
                fullWidth
                variant="outlined"
                sx={{
                  color: "white",
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "white",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "white",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "white",
                  },
                  ".MuiSvgIcon-root": {
                    color: "white",
                  },
                }}
              >
                <MenuItem value="dia">Día</MenuItem>
                <MenuItem value="mes">Mes</MenuItem>
                <MenuItem value="todos">Todos</MenuItem>
              </Select>
            </Grid>
          </Grid>

          {/* Mostrar pickers según el filtro */}
          {filtro === "dia" && (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Selecciona una fecha"
                value={selectedDate}
                onChange={handleDateChange}
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "white",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "white",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "white",
                  },
                  "& .MuiSvgIcon-root": {
                    color: "white",
                  },
                }}
                slotProps={{
                  textField: {
                    InputProps: {
                      style: { color: "white" },
                    },
                    InputLabelProps: {
                      style: { color: "white" },
                    },
                    sx: {
                      "& .MuiInputBase-input": {
                        color: "white", // Texto ingresado
                      },
                      "& .MuiInputLabel-root": {
                        color: "white", // Label
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "white", // Borde por defecto
                        },
                        "&:hover fieldset": {
                          borderColor: "white", // Borde al pasar el cursor
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "white", // Borde al enfocar
                        },
                      },
                      "& .MuiSvgIcon-root": {
                        color: "white", // Ícono del calendario
                      },
                    },
                  },
                }}
              />
            </LocalizationProvider>
          )}

          {filtro === "mes" && (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                views={["year", "month"]}
                label="Selecciona un mes"
                value={selectedMonth}
                onChange={handleMonthChange}
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "white",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "white",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "white",
                  },
                  "& .MuiSvgIcon-root": {
                    color: "white",
                  },
                }}
                slotProps={{
                  textField: {
                    InputProps: {
                      style: { color: "white" },
                    },
                    InputLabelProps: {
                      style: { color: "white" },
                    },
                    sx: {
                      "& .MuiInputBase-input": {
                        color: "white", // Texto ingresado
                      },
                      "& .MuiInputLabel-root": {
                        color: "white", // Label
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "white", // Borde por defecto
                        },
                        "&:hover fieldset": {
                          borderColor: "white", // Borde al pasar el cursor
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "white", // Borde al enfocar
                        },
                      },
                      "& .MuiSvgIcon-root": {
                        color: "white", // Ícono del calendario
                      },
                    },
                  },
                }}
              />
            </LocalizationProvider>
          )}

          {/* Mostrar ganancias y total de torneos filtrados */}
          <div style={{ marginTop: "16px" }}>
            <Typography variant="h6">
              Total de Ganancias: ${gananciaFiltrada.toFixed(2)}
            </Typography>
            <Typography variant="h6">
              Total de Torneos: {totalTorneosFiltrados}
            </Typography>
          </div>

          {/* Lista de torneos filtrados */}
          {torneosFiltrados.length === 0 ? (
            <Typography style={{ marginTop: "16px" }}>
              No hay torneos para mostrar.
            </Typography>
          ) : (
            <TableContainer component={Paper} style={{ marginTop: "16px" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ color: "#1a1a1a" }}>Fecha</TableCell>
                    <TableCell style={{ color: "#1a1a1a" }}>Nombre</TableCell>
                    <TableCell style={{ color: "#1a1a1a" }}>Buy-in</TableCell>
                    <TableCell style={{ color: "#1a1a1a" }}>Compras</TableCell>
                    <TableCell style={{ color: "#1a1a1a" }}>Premio</TableCell>
                    <TableCell style={{ color: "#1a1a1a" }}>Ganancia</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {torneosFiltrados.map((torneo) => {
                    const buyIn = parseNumber(torneo.buyIn);
                    const compras = parseNumber(torneo.compras);
                    const premio = parseNumber(torneo.premio);
                    const gananciaTorneo = premio - buyIn * compras;

                    return (
                      <TableRow key={torneo.id}>
                        <TableCell style={{ color: "#4d4d4d" }}>
                          {torneo.fecha}
                        </TableCell>
                        <TableCell style={{ color: "#4d4d4d" }}>
                          {torneo.nombre || "Sin nombre"}
                        </TableCell>
                        <TableCell style={{ color: "#4d4d4d" }}>
                          ${buyIn.toFixed(2)}
                        </TableCell>
                        <TableCell style={{ color: "#4d4d4d" }}>
                          {compras}
                        </TableCell>
                        <TableCell style={{ color: "#4d4d4d" }}>
                          ${premio.toFixed(2)}
                        </TableCell>
                        <TableCell
                          style={{ color: getColorByValue(gananciaTorneo) }}
                        >
                          ${gananciaTorneo.toFixed(2)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseHistorial} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default RegistradorDeTorneos;
