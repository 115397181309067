// src/components/AdminPanel.js
import React, { useContext } from 'react';
import { AdminContext } from '../contexts/AdminContext';
import AdminTotal from './AdminTotal';
import AdminInferior from './AdminInferior';

function AdminPanel() {
  const { role } = useContext(AdminContext);

  if (role === 'admin_total') {
    return <AdminTotal />;
  } else if (role === 'admin_inferior') {
    return <AdminInferior />;
  } else {
    return <div>No tienes permisos para acceder a este panel.</div>;
  }
}

export default AdminPanel;
