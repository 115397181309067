// src/components/Login.js
import React, { useState, useContext } from 'react';
import { db } from '../firebaseConfig';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';
import '../styles/Login.css'; // Asegúrate de tener este import para los estilos

function Login() {
  const [usuario, setUsuario] = useState('');
  const [clave, setClave] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { setUser } = useContext(UserContext);

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const usuariosRef = collection(db, 'usuarios');
      const q = query(
        usuariosRef,
        where('usuario', '==', usuario),
        where('clave', '==', clave)
      );
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        // Usuario encontrado
        const doc = querySnapshot.docs[0];
        const data = doc.data();
        setUser({ id: doc.id, ...data });
        navigate('/clases'); // Cambiamos la ruta a '/clases'
      } else {
        setError('Usuario o clave no son correctos');
      }
    } catch (error) {
      console.error('Error al iniciar sesión:', error);
      setError('Error al iniciar sesión');
    }
  };

  return (
    <div className="login-container">
      <h2>Iniciar Sesión</h2>
      <form onSubmit={handleLogin} className="login-form">
        <input
          type="text"
          placeholder="Usuario"
          value={usuario}
          onChange={(e) => setUsuario(e.target.value)}
          required
          className="login-input"
        />
        <input
          type="password"
          placeholder="Contraseña"
          value={clave}
          onChange={(e) => setClave(e.target.value)}
          required
          className="login-input"
        />
        <button type="submit" className="login-button">
          Ingresar
        </button>
        {error && <p className="login-error">{error}</p>}
      </form>
    </div>
  );
}

export default Login;
