// src/components/AdminLogin.js
import React, { useContext, useEffect } from 'react';
import { auth, provider } from '../firebaseConfig';
import { signInWithPopup } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { AdminContext } from '../contexts/AdminContext';

function AdminLogin() {
  const { setAdmin } = useContext(AdminContext);
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      setAdmin(user);
      navigate('/admin/dashboard');
    } catch (error) {
      console.error('Error al iniciar sesión como administrador:', error);
    }
  };

  return (
    <div className="admin-login-container">
      <h2>Administrador - Iniciar Sesión</h2>
      <button onClick={handleLogin}>Ingresar con Google</button>
    </div>
  );
}

export default AdminLogin;
