// src/components/AdminTotal.js
import React, { useState, useEffect } from 'react';
import { db } from '../firebaseConfig';
import {
  collection,
  getDocs,
  doc,
  deleteDoc,
  updateDoc,
  addDoc,
  query,
  where,
} from 'firebase/firestore';
import {
  Container,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  bronze: {
    color: '#cd7f32',
    fontWeight: 'bold',
  },
  silver: {
    color: '#C0C0C0',
    fontWeight: 'bold',
  },
  gold: {
    color: '#FFD700',
    fontWeight: 'bold',
  },
  button: {
    margin: '1rem',
  },
  gananciaPositiva: {
    color: 'green',
    fontWeight: 'bold',
  },
  gananciaNegativa: {
    color: 'red',
    fontWeight: 'bold',
  },
});

function AdminTotal() {
  const classes = useStyles();

  // Estados para usuarios
  const [usuarios, setUsuarios] = useState([]);
  const [openUsuariosDialog, setOpenUsuariosDialog] = useState(false);
  const [selectedUsuario, setSelectedUsuario] = useState(null);

  // Estados para clases
  const [clases, setClases] = useState([]);
  const [openClasesDialog, setOpenClasesDialog] = useState(false);
  const [selectedClase, setSelectedClase] = useState(null);

  // Estados para crear usuario
  const [newUser, setNewUser] = useState({ usuario: '', clave: '', rango: 1, agente: '' });
  const [openNewUserDialog, setOpenNewUserDialog] = useState(false);

  // Estados para crear clase
  const [newClase, setNewClase] = useState({ titulo: '', link: '', rango: 1 });
  const [openNewClaseDialog, setOpenNewClaseDialog] = useState(false);

  useEffect(() => {
    fetchUsuarios();
    fetchClases();
  }, []);

  const fetchUsuarios = async () => {
    const usuariosSnapshot = await getDocs(collection(db, 'usuarios'));
    const usuariosData = usuariosSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    // Obtener inicio y fin del mes actual
    const startOfMonth = new Date();
    startOfMonth.setDate(1);
    startOfMonth.setHours(0, 0, 0, 0);

    const endOfMonth = new Date(startOfMonth);
    endOfMonth.setMonth(endOfMonth.getMonth() + 1);

    // Función para formatear fecha a 'YYYY-MM-DD'
    function formatDateToString(date) {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    }

    const startOfMonthString = formatDateToString(startOfMonth);
    const endOfMonthString = formatDateToString(endOfMonth);

    // Obtener ganancias totales para cada usuario en el mes actual
    const usuariosConGanancias = await Promise.all(
      usuariosData.map(async (usuario) => {
        // Obtener torneos del usuario en el mes actual
        const torneosRef = collection(db, 'usuarios', usuario.id, 'torneos');
        const torneosQuery = query(
          torneosRef,
          where('fecha', '>=', startOfMonthString),
          where('fecha', '<', endOfMonthString)
        );
        const torneosSnapshot = await getDocs(torneosQuery);

        let totalGanancias = 0;
        torneosSnapshot.forEach((torneoDoc) => {
          const torneoData = torneoDoc.data();
          // Calcular premio - (buyIn * compras)
          const buyIn = torneoData.buyIn || 0;
          const compras = torneoData.compras || 0;
          const premio = torneoData.premio || 0;
          const gananciaTorneo = premio - buyIn * compras;
          totalGanancias += gananciaTorneo;
        });

        return { ...usuario, totalGanancias };
      })
    );

    setUsuarios(usuariosConGanancias);
  };

  const fetchClases = async () => {
    const clasesSnapshot = await getDocs(collection(db, 'clases'));
    setClases(
      clasesSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
    );
  };

  // Funciones para manejar usuarios
  const handleOpenUsuariosDialog = () => {
    setOpenUsuariosDialog(true);
  };

  const handleCloseUsuariosDialog = () => {
    setOpenUsuariosDialog(false);
    setSelectedUsuario(null);
  };

  const handleEditUsuario = (usuario) => {
    setSelectedUsuario(usuario);
  };

  const handleUpdateUsuario = async () => {
    const confirmacion = window.confirm(
      `¿Estás seguro de actualizar el usuario "${selectedUsuario.usuario}"?`
    );
    if (!confirmacion) return;

    const { id, usuario, clave, rango, agente } = selectedUsuario;
    await updateDoc(doc(db, 'usuarios', id), { usuario, clave, rango, agente });
    fetchUsuarios();
    setSelectedUsuario(null);
  };

  const handleDeleteUsuario = async (id) => {
    const confirmacion = window.confirm(
      '¿Estás seguro de eliminar este usuario?'
    );
    if (!confirmacion) return;

    await deleteDoc(doc(db, 'usuarios', id));
    fetchUsuarios();
  };

  // Funciones para manejar clases
  const handleOpenClasesDialog = () => {
    setOpenClasesDialog(true);
  };

  const handleCloseClasesDialog = () => {
    setOpenClasesDialog(false);
    setSelectedClase(null);
  };

  const handleEditClase = (clase) => {
    setSelectedClase(clase);
  };

  const handleUpdateClase = async () => {
    const confirmacion = window.confirm(
      `¿Estás seguro de actualizar la clase "${selectedClase.titulo}"?`
    );
    if (!confirmacion) return;

    const { id, titulo, link, rango } = selectedClase;
    await updateDoc(doc(db, 'clases', id), { titulo, link, rango });
    fetchClases();
    setSelectedClase(null);
  };

  const handleDeleteClase = async (id) => {
    const confirmacion = window.confirm(
      '¿Estás seguro de eliminar esta clase?'
    );
    if (!confirmacion) return;

    await deleteDoc(doc(db, 'clases', id));
    fetchClases();
  };

  // Funciones para crear usuario
  const handleOpenNewUserDialog = () => {
    setOpenNewUserDialog(true);
  };

  const handleCloseNewUserDialog = () => {
    setOpenNewUserDialog(false);
    setNewUser({ usuario: '', clave: '', rango: 1, agente: '' });
  };

  const handleCreateUser = async () => {
    const confirmacion = window.confirm(
      `¿Estás seguro de crear el usuario "${newUser.usuario}"?`
    );
    if (!confirmacion) return;

    await addDoc(collection(db, 'usuarios'), newUser);
    fetchUsuarios();
    handleCloseNewUserDialog();
  };

  // Funciones para crear clase
  const handleOpenNewClaseDialog = () => {
    setOpenNewClaseDialog(true);
  };

  const handleCloseNewClaseDialog = () => {
    setOpenNewClaseDialog(false);
    setNewClase({ titulo: '', link: '', rango: 1 });
  };

  const handleCreateClase = async () => {
    const confirmacion = window.confirm(
      `¿Estás seguro de crear la clase "${newClase.titulo}"?`
    );
    if (!confirmacion) return;

    await addDoc(collection(db, 'clases'), newClase);
    fetchClases();
    handleCloseNewClaseDialog();
  };

  return (
    <Container maxWidth="md" style={{ marginTop: '2rem' }}>
      <Typography variant="h4" align="center" gutterBottom>
        Panel de Administración
      </Typography>

      {/* Botones de acciones */}
      <div
        style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}
      >
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={handleOpenNewUserDialog}
        >
          Crear Nuevo Usuario
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={handleOpenUsuariosDialog}
        >
          Ver Usuarios
        </Button>
        <Button
          variant="contained"
          color="secondary"
          className={classes.button}
          onClick={handleOpenNewClaseDialog}
        >
          Agregar Nueva Clase
        </Button>
        <Button
          variant="contained"
          color="secondary"
          className={classes.button}
          onClick={handleOpenClasesDialog}
        >
          Ver Clases
        </Button>
      </div>

      {/* Dialogo para crear nuevo usuario */}
      <Dialog open={openNewUserDialog} onClose={handleCloseNewUserDialog}>
        <DialogTitle>Crear Nuevo Usuario</DialogTitle>
        <DialogContent>
          <TextField
            label="Usuario"
            fullWidth
            value={newUser.usuario}
            onChange={(e) =>
              setNewUser({ ...newUser, usuario: e.target.value })
            }
            style={{ marginBottom: '1rem' }}
          />
          <TextField
            label="Contraseña"
            type="password"
            fullWidth
            value={newUser.clave}
            onChange={(e) => setNewUser({ ...newUser, clave: e.target.value })}
            style={{ marginBottom: '1rem' }}
          />
          <FormControl fullWidth style={{ marginBottom: '1rem' }}>
            <InputLabel>Rango</InputLabel>
            <Select
              value={newUser.rango}
              onChange={(e) => setNewUser({ ...newUser, rango: e.target.value })}
            >
              <MenuItem value={1}>Bronce</MenuItem>
              <MenuItem value={2}>Plata</MenuItem>
              <MenuItem value={3}>Oro</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Agente"
            fullWidth
            value={newUser.agente}
            onChange={(e) =>
              setNewUser({ ...newUser, agente: e.target.value })
            }
            style={{ marginBottom: '1rem' }}
            placeholder="Opcional"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseNewUserDialog} color="secondary">
            Cancelar
          </Button>
          <Button onClick={handleCreateUser} color="primary">
            Crear
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialogo para ver y editar usuarios */}
      <Dialog
        open={openUsuariosDialog}
        onClose={handleCloseUsuariosDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Lista de Usuarios</DialogTitle>
        <DialogContent>
          <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Usuario</TableCell>
                  <TableCell>Agente</TableCell>
                  <TableCell>Rango</TableCell>
                  <TableCell>Clave</TableCell>
                  <TableCell>Ganancias Totales</TableCell>
                  <TableCell>Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {usuarios.map((usuario) => (
                  <TableRow key={usuario.id}>
                    <TableCell>{usuario.usuario}</TableCell>
                    <TableCell>
                      {usuario.agente ? usuario.agente : 'Sin Agente'}
                    </TableCell>
                    <TableCell>
                      {usuario.rango === 1 && (
                        <span className={classes.bronze}>Bronce</span>
                      )}
                      {usuario.rango === 2 && (
                        <span className={classes.silver}>Plata</span>
                      )}
                      {usuario.rango === 3 && (
                        <span className={classes.gold}>Oro</span>
                      )}
                    </TableCell>
                    <TableCell>{usuario.clave}</TableCell>
                    <TableCell
                      className={
                        usuario.totalGanancias >= 0
                          ? classes.gananciaPositiva
                          : classes.gananciaNegativa
                      }
                    >
                      u$
                      {usuario.totalGanancias
                        ? usuario.totalGanancias.toLocaleString('es-ES')
                        : 0}
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleEditUsuario(usuario)}
                        style={{ marginRight: '0.5rem' }}
                      >
                        Editar
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleDeleteUsuario(usuario.id)}
                      >
                        Eliminar
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>

          {/* Mostrar el total de usuarios */}
          <Typography
            variant="h6"
            align="right"
            style={{ marginTop: '1rem', fontWeight: 'bold' }}
          >
            Usuarios totales: {usuarios.length}
          </Typography>

          {/* Formulario para editar usuario */}
          {selectedUsuario && (
            <div style={{ marginTop: '1rem' }}>
              <Typography variant="h6">Editar Usuario</Typography>
              <TextField
                label="Usuario"
                fullWidth
                value={selectedUsuario.usuario}
                onChange={(e) =>
                  setSelectedUsuario({
                    ...selectedUsuario,
                    usuario: e.target.value,
                  })
                }
                style={{ marginBottom: '1rem' }}
              />
              <TextField
                label="Contraseña"
                type="password"
                fullWidth
                value={selectedUsuario.clave}
                onChange={(e) =>
                  setSelectedUsuario({
                    ...selectedUsuario,
                    clave: e.target.value,
                  })
                }
                style={{ marginBottom: '1rem' }}
              />
              <FormControl fullWidth style={{ marginBottom: '1rem' }}>
                <InputLabel>Rango</InputLabel>
                <Select
                  value={selectedUsuario.rango}
                  onChange={(e) =>
                    setSelectedUsuario({
                      ...selectedUsuario,
                      rango: e.target.value,
                    })
                  }
                >
                  <MenuItem value={1}>Bronce</MenuItem>
                  <MenuItem value={2}>Plata</MenuItem>
                  <MenuItem value={3}>Oro</MenuItem>
                </Select>
              </FormControl>
              <TextField
                label="Agente"
                fullWidth
                value={selectedUsuario.agente || ''}
                onChange={(e) =>
                  setSelectedUsuario({
                    ...selectedUsuario,
                    agente: e.target.value,
                  })
                }
                style={{ marginBottom: '1rem' }}
                placeholder="Opcional"
              />
              <div style={{ marginTop: '1rem' }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleUpdateUsuario}
                  style={{ marginRight: '0.5rem' }}
                >
                  Guardar Cambios
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => setSelectedUsuario(null)}
                >
                  Cancelar
                </Button>
              </div>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseUsuariosDialog} color="secondary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialogo para crear nueva clase */}
      <Dialog open={openNewClaseDialog} onClose={handleCloseNewClaseDialog}>
        <DialogTitle>Agregar Nueva Clase</DialogTitle>
        <DialogContent>
          <TextField
            label="Título"
            fullWidth
            value={newClase.titulo}
            onChange={(e) =>
              setNewClase({ ...newClase, titulo: e.target.value })
            }
            style={{ marginBottom: '1rem' }}
          />
          <TextField
            label="Link"
            fullWidth
            value={newClase.link}
            onChange={(e) =>
              setNewClase({ ...newClase, link: e.target.value })
            }
            style={{ marginBottom: '1rem' }}
          />
          <FormControl fullWidth>
            <InputLabel>Rango</InputLabel>
            <Select
              value={newClase.rango}
              onChange={(e) =>
                setNewClase({ ...newClase, rango: e.target.value })
              }
            >
              <MenuItem value={1}>Bronce</MenuItem>
              <MenuItem value={2}>Plata</MenuItem>
              <MenuItem value={3}>Oro</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseNewClaseDialog} color="secondary">
            Cancelar
          </Button>
          <Button onClick={handleCreateClase} color="primary">
            Crear
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialogo para ver y editar clases */}
      <Dialog
        open={openClasesDialog}
        onClose={handleCloseClasesDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Lista de Clases</DialogTitle>
        <DialogContent>
          <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Título</TableCell>
                  <TableCell>Link</TableCell>
                  <TableCell>Rango</TableCell>
                  <TableCell>Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {clases.map((clase) => (
                  <TableRow key={clase.id}>
                    <TableCell>{clase.titulo}</TableCell>
                    <TableCell>{clase.link}</TableCell>
                    <TableCell>
                      {clase.rango === 1 && (
                        <span className={classes.bronze}>Bronce</span>
                      )}
                      {clase.rango === 2 && (
                        <span className={classes.silver}>Plata</span>
                      )}
                      {clase.rango === 3 && (
                        <span className={classes.gold}>Oro</span>
                      )}
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleEditClase(clase)}
                        style={{ marginRight: '0.5rem' }}
                      >
                        Editar
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleDeleteClase(clase.id)}
                      >
                        Eliminar
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>

          {/* Formulario para editar clase */}
          {selectedClase && (
            <div style={{ marginTop: '1rem' }}>
              <Typography variant="h6">Editar Clase</Typography>
              <TextField
                label="Título"
                fullWidth
                value={selectedClase.titulo}
                onChange={(e) =>
                  setSelectedClase({ ...selectedClase, titulo: e.target.value })
                }
                style={{ marginBottom: '1rem' }}
              />
              <TextField
                label="Link"
                fullWidth
                value={selectedClase.link}
                onChange={(e) =>
                  setSelectedClase({ ...selectedClase, link: e.target.value })
                }
                style={{ marginBottom: '1rem' }}
              />
              <FormControl fullWidth>
                <InputLabel>Rango</InputLabel>
                <Select
                  value={selectedClase.rango}
                  onChange={(e) =>
                    setSelectedClase({
                      ...selectedClase,
                      rango: e.target.value,
                    })
                  }
                >
                  <MenuItem value={1}>Bronce</MenuItem>
                  <MenuItem value={2}>Plata</MenuItem>
                  <MenuItem value={3}>Oro</MenuItem>
                </Select>
              </FormControl>
              <div style={{ marginTop: '1rem' }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleUpdateClase}
                  style={{ marginRight: '0.5rem' }}
                >
                  Guardar Cambios
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => setSelectedClase(null)}
                >
                  Cancelar
                </Button>
              </div>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseClasesDialog} color="secondary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default AdminTotal;
