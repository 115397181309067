// src/components/Clases.js
import React, { useEffect, useState, useContext } from "react";
import { db } from "../firebaseConfig";
import { collection, getDocs } from "firebase/firestore";
import "../styles/Clases.css";
import { UserContext } from "../contexts/UserContext";

function Clases() {
  const [clases, setClases] = useState([]);
  const [selectedClase, setSelectedClase] = useState(null);
  const { user } = useContext(UserContext);

  useEffect(() => {
    const fetchClases = async () => {
      try {
        const clasesRef = collection(db, "clases");
        const snapshot = await getDocs(clasesRef);
        const clasesData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setClases(clasesData);
      } catch (error) {
        console.error("Error al obtener las clases:", error);
      }
    };

    fetchClases();
  }, []);

  const handleClaseClick = (clase) => {
    setSelectedClase(clase);
  };

  const handleCloseVideo = () => {
    setSelectedClase(null);
  };

  const getYouTubeVideoId = (url) => {
    const regex =
      /(?:youtube\.com\/(?:[^\/\n\s]+\/\S*\/|(?:v|embed)\/|.*[?&]v=)|youtu\.be\/)([^#&?\/\n\s]+)/;
    const match = url.match(regex);
    return match && match[1] ? match[1] : null;
  };

  if (!user) {
    return <p>Cargando...</p>;
  }

  const clasesFiltradas = clases.filter((clase) => clase.rango <= user.rango);

  return (
    <div className="clases-container">
      <h2>Clases Disponibles</h2>
      {clasesFiltradas.length === 0 ? (
        <p>No hay clases disponibles para tu rango.</p>
      ) : (
        <div className="clases-grid">
          {clasesFiltradas.map((clase) => {
            const videoId = getYouTubeVideoId(clase.link);
            const thumbnailUrl = videoId
              ? `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`
              : "/images/default-thumbnail.jpg";

            return (
              <div
                key={clase.id}
                className="clase-card"
                onClick={() => handleClaseClick(clase)}
              >
                <img
                  src={thumbnailUrl}
                  alt={clase.titulo}
                  className="clase-thumbnail"
                />
                <h3>{clase.titulo}</h3>
                {/* Puedes agregar el rango requerido para la clase */}
                {/* <p>Rango requerido: {clase.rango}</p> */}
              </div>
            );
          })}
        </div>
      )}

      {selectedClase && (
        <div className="video-modal">
          <div className="video-content">
            <span className="close-button" onClick={handleCloseVideo}>
              &times;
            </span>
            <h2>{selectedClase.titulo}</h2>
            <div className="video-wrapper">
              <iframe
                src={`${selectedClase.link}?autoplay=1&modestbranding=1&rel=0&fs=1`}
                frameBorder="0"
                allow="autoplay; encrypted-media"
                allowFullScreen
                title={selectedClase.titulo}
              ></iframe>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Clases;
