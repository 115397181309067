// src/components/Navbar.js
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { UserContext } from "../contexts/UserContext";
import "../styles/Navbar.css"; // Importamos los estilos

function Navbar() {
  const { user, setUser } = useContext(UserContext);

  const handleLogout = () => {
    setUser(null);
  };

  // Función para obtener el nombre del rango y aplicar estilos
  const getRangoInfo = (rango) => {
    switch (rango) {
      case 1:
        return { nombre: "Bronce", clase: "rango-bronce" };
      case 2:
        return { nombre: "Plata", clase: "rango-plata" };
      case 3:
        return { nombre: "Oro", clase: "rango-oro" };
      default:
        return { nombre: "Desconocido", clase: "" };
    }
  };

  const rangoInfo = user ? getRangoInfo(user.rango) : null;

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography
          variant="h6"
          color="inherit"
          component={Link}
          to="/"
          style={{ flexGrow: 1, textDecoration: "none" }}
        >
          La Pecera Club
        </Typography>

        <Button color="inherit" component={Link} to="/">
          Inicio
        </Button>
        {user ? (
          <>
            <Button color="inherit" component={Link} to="/clases">
              Clases
            </Button>
            <Button color="inherit" component={Link} to="/torneos">
              Registrador de Torneos
            </Button>
            {/* Otros botones si es necesario */}
            <div className={`usuario-info ${rangoInfo.clase}`}>
              <span>{user.usuario}</span>
            </div>
            <Button color="inherit" onClick={handleLogout}>
              Cerrar sesión
            </Button>
          </>
        ) : (
          <Button color="inherit" component={Link} to="/login">
            Ingresar
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
