// src/firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAtnF1C5C3VWLsmgQRmH_We6TeZ_RWQ60Q",
    authDomain: "pecera-club-e4971.firebaseapp.com",
    projectId: "pecera-club-e4971",
    storageBucket: "pecera-club-e4971.appspot.com",
    messagingSenderId: "816763109017",
    appId: "1:816763109017:web:bbdfb62fbf449c94d50208"
  };

  // Inicializar Firebase
const app = initializeApp(firebaseConfig);

// Servicios de Firebase
const auth = getAuth(app);
const db = getFirestore(app);
const provider = new GoogleAuthProvider();
const storage = getStorage(app);

export { db, auth, provider, storage };