// src/contexts/AdminContext.js
import React, { createContext, useState, useEffect } from 'react';
import { auth, db } from '../firebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';

export const AdminContext = createContext();

export const AdminProvider = ({ children }) => {
  const [admin, setAdmin] = useState(null);
  const [role, setRole] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // Obtener el rol del administrador desde Firestore
        const adminDoc = await getDoc(doc(db, 'administradores', user.uid));
        if (adminDoc.exists()) {
          const adminData = adminDoc.data();
          setAdmin(user);
          setRole(adminData.role); // 'admin_total' o 'admin_inferior'
        } else {
          // No es un administrador válido
          setAdmin(null);
          setRole(null);
        }
      } else {
        setAdmin(null);
        setRole(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  return (
    <AdminContext.Provider value={{ admin, setAdmin, role, loading }}>
      {children}
    </AdminContext.Provider>
  );
};
