// src/components/ProtectedAdminRoute.js
import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AdminContext } from '../contexts/AdminContext';

function ProtectedAdminRoute({ children }) {
  const { admin, loading } = useContext(AdminContext);

  if (loading) {
    return <div>Cargando...</div>;
  }

  if (!admin) {
    return <Navigate to="/admin" />;
  }

  return children;
}

export default ProtectedAdminRoute;
